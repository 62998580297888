




































import { Component, Vue } from "vue-property-decorator";

import { IInfo } from "../Services/Interfaces";
import { InfoType } from "../Services/enums";
import {
  globalInfoService
} from "../Services/GlobalService";


@Component
export default class Intro extends Vue {
  constructor() {
    super();
    this.name = "";
    this.viewmodel = <IInfo>{};
    this.warnclass = "alert alert-warning";
    this.loading = false;
  }

  name: string;
  loading: boolean;
  viewmodel: IInfo;
  warnclass: string;


  created() {
    globalInfoService.getMessage().subscribe(i => {
      if (i.type === InfoType.debug) {
        console.debug(JSON.stringify(i));
        return;
      }
      this.viewmodel = i;
      this.loading = i.type == InfoType.loading ? true : false;
      if (i.type === InfoType.warning) {
        this.warnclass = "alert alert-warning";
      } else if (i.type === InfoType.error) {
        this.warnclass = "alert alert-error";
      } else if (i.type == InfoType.info) {
        this.warnclass = "alert alert-info";
      } else if (i.type == InfoType.success) {
        this.warnclass = "alert alert-success";
      }
    });

  }
}

